body{
    font-size: 15px;
}

h1{
    font-weight: 600;
}

.form-control {
    height: 48px;
    line-height: 48px;
    width: 100%;
    padding: 0 20px;
    border: 1px solid #D4D4D4;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border-radius: 4px;
    font-size: 15px;
}


.ui-slider {
    margin-top: 20px !important;
}

.ui-slider-horizontal {
    height: 0.2em !important;
}
*
.ui-widget-header {
    border: none;
    background: #e9511c !important;
}

.inner-pages .blog .ui-widget.ui-widget-content {
    background: #212121;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    border: 3px solid #e9511c !important;
    background: #f5f7fb !important;
    border-radius: 50%;
    transition: background .3s ease-out;
}

.ui-slider .ui-slider-handle {
    cursor: pointer !important;
    top: -0.5em !important;
}

.ui-slider .ui-slider-handle:hover{
    background: #e9511c !important;
}

.inner-pages .blog .ui-widget.ui-widget-content {
    margin-bottom: 5px !important;
}


#filterForm a small{
    color: #333;
}

.pagination-wrapper{
    display: flex;
}

.pagination{
    margin: 0 auto;
}

.bg-orange a{
    color: #FFF;
}

span.seo-city-ad {
    display: block;
    text-indent: -5000px;
    font-size: .2em;
}

h3.count-results {
    font-size: 1em;
}

h2.subtitle {
    font-size: 1.25rem;
}

h2.subtitle:after{
    color: #e9511c !important;
    display: block !important;
    height: 3px !important;
    font-weight: bold !important;
    background-color: #e9511c !important;
    content: " " !important;
    width: 50px !important;
    margin-top: 0.5rem !important;
    margin-bottom: 1.5rem !important;
}

.inner-pages .headings-2 .listing-title-bar .price {
    font-size: 28px;
    font-weight: 600 !important;
    color: #e9511c;
    text-transform: capitalize !important;
}

.agents #header, .sin-1 #header{
    box-shadow: none;
}

.inner-pages .blog .homes-content .homes-list li {
    width: 100%;
}

.inner-pages .blog .homes-content .homes-list li i{
    font-size: 0.8em;
    width: 20px;
    text-align: center;
}

textarea.form-control{
    height: 200px;
}

.mb-0{
    margin-bottom: 0 !important;
}

.pb-0{
    padding-bottom: 0 !important;
}

.mt-0{
    margin-top: 0 !important;
}

.pt-0{
    padding-top: 0 !important;
}


.portfolio .project-head:before{
    pointer-events: none;
}


h2 {
    font-weight: 600;
}

.portfolio .homes-content {
    padding-bottom: 1px !important;
}

.portfolio .footer a.btn{
    margin-bottom: 15px;
}

.homepage-9 .portfolio .homes-content .homes-address {
    margin-top: 0px;
    min-height: 140px;
}

.inner-pages .blog-section .news-item-text.details{
    padding: 20px !important;
}

.inner-pages .blog-section .news-item-descr.big-news.details{
    height: auto !important;
}

.first-footer .top-footer{
    background-color: #303441;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.first-footer ul li a, .first-footer h3, .first-footer .bloq-email .email input{
    color: #FFF !important;
}

.list{
    padding-top: 20px !important;
    margin-left: 20px;
    padding-left: 0;
}

.list li:before{
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    position: relative;
    margin-top: 7px;
    float: left;
    margin-right: 10px;
    border-radius: 50%;
}

.list li{
    list-style: none;
    margin-bottom: 10px;
}


.orange-list li:before{
    background-color: #e9511c;
}

.accordion h3{
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;

}

.accordion li.active h3{
    color: #FFF;
}

.ui-elements .accordion-1 .content p {
    padding: 5px 13px;
    margin-bottom: 0.5rem;
}

.ui-elements .accordion li.active .content{
    padding: 15px;
}

.ui-elements .accordion li.active .content{
    max-height: 1000px;
}

ul.menu-top{
    margin-top: 10px;
}

#navigation.style-1 ul li:hover ul li:hover ul li a {
    color: #121212 !important;
}

#navigation.style-1 ul li:hover ul li:hover ul li:hover a {
    color: #FFF !important;
}
.header.head-tr {
    position: absolute;
    width: 100%;
    z-index: 999;
    background: #FFF;
}

.menu-top, .my-header-top{
    z-index: 500;
}

.mmenu-trigger {
    top: 10px;
    background: #e9511c;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: #FFF;
}

@media only screen and (max-width: 1024px){
    #navigation{
        display:none;
    }
    #logo{
        position: absolute;
        width: 60px;
        text-align: center;
        z-index: 1049;
        margin-top: -5px;
    }
    #logo-baseline {
        display: none;
    }
    .hp-6 .parallax-searchs.home15.thome-6 .hero-inner {
        padding: 0px 0;
    }
    .hp-6 .rld-single-select.ml-22{
        margin-left: 0;
    }

    .modal{
        position: absolute;
    }
}

@media only screen and (max-width: 991px){
    .hp-6 .rld-main-search .rld-single-input, .hp-6 .rld-main-search .rld-single-select .single-select {
        width: 100%;
    }
    .hp-6 .rld-main-search {
        height: auto;
    }

    .serv-flex-img{
        display: none;
    }
    .portfolio .footer {
        padding-bottom: 15px;
    }
    #immodvisor-seal-widget-iframe{
        display:none !important;
    }

    .first-footer .nav-footer{
        width: 100%;
        text-align: center;
        display: block;
    }
    .first-footer .netabout {
        text-align: center;
    }
    .why-mtc .service-text-p {
     margin-top: 0 !important;
    }
}

.inner-pages .headings {
    height: 40vh;
}

.inner-pages .headings h1 {
    margin-top: 10.5rem;
}

.bloq-email .email{
    height: auto;
}

.first-footer.inpage .netsocials a .fa-youtube{
    margin-right: 2.5rem;
}
.first-footer.inpage .netsocials a .fa-pinterest{
    margin-right: 0;
}

.first-footer .netsocials a .fa-pinterest {
    margin-right: 0px;
}

.first-footer .netsocials a .fa-youtube {
    margin-right: 1.5rem;
}
.entries-wrapper:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
    width: 4px;
    background-color: #e9511c;
    z-index: 1;
}

.entries{
    width:calc(100% - 80px);
    max-width:800px;
    margin:auto;
    position: relative;
    left:-5px;
}
.entries .entry{
    width:calc(50% - 44px);
    float:left;
    padding:20px;
    clear:both;
    text-align:right;
}
.entries .entry:not(:first-child){
    margin-top:-60px;
}
.entries .entry .title{
    font-size:32px;
    margin-bottom:12px;
    position: relative;
    color:#e9511c;
    font-weight: 600;
}
.entries .entry .title:before {
    content: '';
    position: absolute;
    width:8px;
    height:8px;
    border:4px solid #e9511c;
    background-color:#FFF;
    border-radius:100%;
    top:50%;
    transform:translateY(-50%);
    right:-73px;
    z-index:1000;
}
.entries .entry .title.big:before {
    width:24px;
    height:24px;
    transform:translate(8px,-50%);
}

.entries .entry .body {
    color:#212121;
}
.entries .entry .body p {
    line-height:1.4em;
}


.entries .entry:nth-child(2n){
    text-align:left;
    float:right;
}

.entries .entry:nth-child(2n) .title:before{
    left:-63px;
}
.entries .entry:nth-child(2n) .title.big:before {
    transform:translate(-8px,-50%);
}

.how-it-works article img.subimg{
    padding-right: 0;
}

.how-it-works .service-1 .serv:hover, .how-it-works .service-2 .serv:hover{
    cursor: default !important;
}

img.mfp-img {
    background: #000;
}

.mfp-bg {
    z-index: 999991;
    opacity: 1;
}

.mfp-wrap {
    z-index: 999992;
}


.mfp-arrow.mfp-arrow-left:before, .mfp-arrow.mfp-arrow-right:before{
    content: "";
}
.mfp-arrow.mfp-arrow-left:before{
    left: -15px;
    top: -25px;
}

.mfp-arrow.mfp-arrow-right:before{
    left: -15px;
    top: -25px;
}

.mfp-arrow-left:after {
    border-right: 17px solid #EEE;
    margin-left: 17px;
}

.mfp-arrow-right:after {
    border-left: 17px solid #EEE;
    margin-left: 24px;
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: -17px;
}

.single-portfolio{
    margin-bottom: 25px;
}

.popup-images{
    border: 1px solid #CCC;
}

.mfp-gallery .mfp-content .mfp-close{
    display: block;
}

.mfp-close{
    cursor: pointer !important;
}

img.mfp-img {
    margin: 0 auto;
    border-radius: 0;
}

.mfp-arrow:active {
    margin-top: 0;
}

@media (min-width: 992px){
    .first-footer .bloq-email .email .col-lg-6, .first-footer .bloq-email .email .col-md-6, .first-footer .bloq-email .email .location-container {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.first-footer .bloq-email .email input, .first-footer .bloq-email .email textarea{
    border: 0px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    padding: 0.8rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    float: left;
    border-radius: 0;
}

.first-footer .bloq-email .email textarea, .first-footer .bloq-email .email div.text-left, .first-footer .bloq-email .email p{
    display:none;
}

.first-footer .bloq-email .email .row {
    margin: 0;
    width: 100%;
}

.first-footer .bloq-email .email button[type="submit"] {
    border: 0px;
    padding: 0.8rem;
    background: #e9511c;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 1.5rem;
    cursor: pointer;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    border-radius: 0;
    width: 100%;
}

.first-footer .bloq-email{
    margin-left: -15px;
}

@media screen and (max-width: 991px){
    .bloq-email .email {
        -ms-flex-wrap: nowrap;
        flex-wrap: inherit;
    }

    .first-footer .netsocials {
            display: block;
}
}

#parrainage-form{
    margin-top: 15px;
    margin-bottom: 35px;

    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid #EEE;
    border-bottom: 1px solid #EEE;
}

.homepage-9 .sec-title h2{
    text-transform: none;
}

.inner-pages .blog-section .resp-img, .blog-section .resp-img{
    height: auto;
}

.serv-flex-img {
    position: relative;
    margin: 0 auto;
    top: -265px;
}

.why-mtc .service-text-p {
    margin-top: -265px;
}

.dropdown-menu{
    z-index: 1051;
}

.portfolio .homes-content .homes-address .fa-map-marker{
    color: #e9511c;
}

.portfolio .project-single:hover .project-head:before{
    opacity: .5;
}

.my-header-top .left-side{
    width: 100px;
}

.bg-cream{
    background-color: #EBE6DE;
}

.serv-flex-text .home-img{
    width: 50px !important;
    height: 50px !important;
    padding: 0 !important;
}